<template>
  <div>
    <div class="table_item_box">
      <div class="table_box">
        <el-table :data="withdrawTable" style="width: 100%">
          <el-table-column prop="date" :label="$t('common.column.date')" min-width="100"></el-table-column>
          <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
            <template slot-scope="accountScope">
              {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.method')" min-width="120">
            <template slot-scope="methodScope">
              <span v-if="methodScope.row.method === 2">
                {{ showMethod2WithdrawMethod(methodScope.row.transferCountry) }}
              </span>
              <span v-else>{{ showWithdrawMethod(methodScope.row.method) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.amt')" min-width="120">
            <template slot-scope="amountScope">
              {{ amountScope.row.currency | currencyToSymbol }}
              {{ amountScope.row.amount | formatNumber(2) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.status')" min-width="100">
            <template slot-scope="statusScope">
              {{
                getWithdrawStatus(statusScope.row.status, statusScope.row.processedByOp, statusScope.row.reversedType)
                  | transactionStatus
              }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" v-if="showCancelWithdraw">
            <template slot-scope="statusScope">
              <el-button
                v-if="showCancelButton(statusScope.row.status, statusScope.row.processedByOp)"
                @click="cancelWithdraw(statusScope.row)"
                size="mini"
                round
                plain
                >{{ $t('common.button.cancel') }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="page_box">
      <el-pagination
        :background="true"
        :page-size="25"
        layout="prev, pager, next"
        :total="withdrawTotal"
        :page-sizes="[25, 50, 100]"
        @current-change="handleWithdrawCurrentChange"
        @size-change="handleWithdrawSizeChange"
        :current-page.sync="withdrawPageNo"
        data-testid="pagination"
      ></el-pagination>
    </div>
    <!-- status -->
    <div class="status_box">
      <p class="title">{{ $t('common.field.statusDef') }}</p>
      <ul>
        <li>
          <strong>{{ $t('common.field.submitted') }}</strong>
          <p>{{ $t('transcationHistory.withdrawDesc.sub') }}</p>
        </li>
        <li>
          <strong>{{ $t('common.field.successful') }}</strong>
          <p>{{ $t('transcationHistory.withdrawDesc.suc') }}</p>
        </li>
        <li>
          <strong>{{ $t('common.field.processing') }}</strong>
          <i18n path="transcationHistory.withdrawDesc.proc" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_WITHDRAWAL_EMAIL">{{ GLOBAL_WITHDRAWAL_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.incomplete') }}</strong>
          <i18n path="transcationHistory.withdrawDesc.incomp" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_WITHDRAWAL_EMAIL">{{ GLOBAL_WITHDRAWAL_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.cancelled') }}</strong>
          <i18n path="transcationHistory.withdrawDesc.cancel" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_WITHDRAWAL_EMAIL">{{ GLOBAL_WITHDRAWAL_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.failed') }}</strong>
          <i18n path="transcationHistory.withdrawDesc.fail" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_WITHDRAWAL_EMAIL">{{ GLOBAL_WITHDRAWAL_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.rejected') }}</strong>
          <i18n path="transcationHistory.withdrawDesc.rej" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_WITHDRAWAL_EMAIL">{{ GLOBAL_WITHDRAWAL_EMAIL }}</a>
            </template>
          </i18n>
        </li>
      </ul>
    </div>
    <vDialog :visible.sync="dialogVisible" :visibleFooter="false" :top="'300px'">
      <div class="cancelWithdrawDialog">
        <h5 v-html="$t('transcationHistory.cancelWithdraw')"></h5>
        <el-button class="white_button" @click="closeDialog">{{ $t('common.field.no') }}</el-button>
        <el-button class="purple_button" @click="submitCancelWithdraw()">{{ $t('common.field.yes') }}</el-button>
      </div>
    </vDialog>
  </div>
</template>

<script>
import mixin from '@/mixins/transactions/transactionHistory';
import vDialog from '@/components/home/vDialog';
import { apiCancelWithdraw } from '@/resource';

export default {
  mixins: [mixin],
  components: { vDialog },
  data() {
    return {
      dialogVisible: false,
      cancelInfo: null,
      withdrawPageNo: 1,
      withdrawPageSize: 25,
      withdrawUrl: 'withdraw',
      withdrawTotal: 0,
      withdrawTable: [],
      withdrawMethods: this.$config.getWithdrawMethods(this.$store.state.common.regulator),
      withdrawStatus: {
        // WITHDRAW_SUBMIT(1), // 提交中
        // WITHDRAW_CANCEL(2), // 取消
        // WITHDRAW_AUDIT(3), // 审核中
        // WITHDRAW_REFUSE(4), // 拒绝
        // WITHDRAW_ACCEPT(5), // 受理
        // WITHDRAW_PAYFAIL(6), // 打款失败
        // WITHDRAW_SUCCESS(7), // 出金成功
        // WITHDRAW_FAIL(8), // 出金失败
        // WITHDRAW_PENDING(9), // 没有决定的状态，挂着
        // WITHDRAW_HALFFAILED(10); // 部分失败
        1: 'Submitted',
        2: 'Cancelled',
        3: 'Processing',
        4: 'Rejected',
        5: 'Processing',
        6: 'Failed',
        7: 'Successful',
        8: 'Failed',
        9: 'Processing',
        10: 'Failed',
        20: 'Processing'
      },
      withdrawReversedType: {
        // 1 Cancelled by client at accept
        // 2 Reversed by op at accept
        // 3 Reversed by op at complete
        1: 'Cancelled',
        2: 'Failed',
        3: 'Failed'
      }
    };
  },
  methods: {
    cancelWithdraw(info) {
      this.dialogVisible = true;
      this.cancelInfo = info;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.cancelInfo = null;
    },
    showCancelButton(status, processedByOp) {
      return !processedByOp && (status === 1 || status === 3 || status === 5);
    },
    getWithdrawStatus(status, processedByOp, reversedType) {
      if (reversedType) return this.withdrawReversedType[reversedType];
      return !processedByOp && (status === 3 || status === 5) ? this.withdrawStatus[1] : this.withdrawStatus[status];
    },
    submitCancelWithdraw() {
      apiCancelWithdraw({ withdrawalId: this.cancelInfo.id })
        .then(result => {
          if (result.data.code == 0) {
            this.closeDialog();
            this.initTransactionHistory();
          } else {
            this.showErrorMsg();
          }
        })
        .catch(err => {
          this.showErrorMsg();
        });
    },
    showErrorMsg() {
      this.loading = false;
      this.$message({
        message: this.$t('transcationHistory.cancelError'),
        type: 'error',
        duration: 10000
      });
      this.closeDialog();
      this.initTransactionHistory();
    },
    handleWithdrawCurrentChange(pageNo) {
      this.withdrawPageNo = pageNo;
      this.initTransactionHistory();
    },
    handleWithdrawSizeChange(size) {
      this.withdrawPageSize = size;
      this.initTransactionHistory();
    },
    showWithdrawMethod(method) {
      return this.withdrawMethods[method] ? this.$options.filters.channelMethods(this.withdrawMethods[method]) : method;
    },
    showMethod2WithdrawMethod(transferCountry) {
      return this.withdrawMethods[2][transferCountry]
        ? this.$options.filters.channelMethods(this.withdrawMethods[2][transferCountry])
        : transferCountry;
    },
    initTransactionHistory() {
      this.queryTransactionHistory(this.withdrawUrl, this.withdrawPageNo, this.withdrawPageSize).then(resp => {
        if (resp.data.code === 0) {
          this.withdrawTotal = resp.data.data.total;
          this.withdrawTable = resp.data.data.withdrawHistory;
        }
      });
    }
  },
  computed: {
    showCancelWithdraw() {
      const found = this.withdrawTable.find(
        element =>
          element.processedByOp === null && (element.status === 1 || element.status === 3 || element.status === 5)
      );
      return this.$config.getCancelWithdraw(this.regulator) && found;
    }
  },
  mounted() {
    this.initTransactionHistory();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
