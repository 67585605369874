<template>
  <div class="common_dialog_wrapper" ref="home_dialog">
    <el-dialog
      :visible="visible"
      top="0"
      @close="close"
      :append-to-body="needToBody"
      :fullscreen="needFullscreen || fullscreen"
      :close-on-click-modal="enableCloseOnBackground"
      @open="open"
      center
    >
      <div slot="title">
        <slot name="header"></slot>
        <img v-if="needCloseButton" src="@/assets/images/close.png" class="closeImg" alt @click="close" />
      </div>
      <slot></slot>
      <!-- <div v-if="visibleFooter" slot="footer">
        <a @click="showLiveChat" data-testid="liveChat">
          <img src="@/assets/images/login/footer_chat_icon.png" alt />
          <span class="help" v-html="$t('common.liveChat.desc')"></span>
        </a>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'home_dialog',
  props: {
    visible: Boolean,
    visibleFooter: Boolean,
    needFullscreen: Boolean,
    needToBody: { type: Boolean, default: false },
    needCloseButton: { type: Boolean, default: true },
    enableCloseOnBackground: { type: Boolean, default: true }
  },
  data() {
    return {
      fullscreen: false
    };
  },
  watch: {
    visible(bool) {
      if (bool) this.$nextTick(() => this.setFullscreen());
    }
  },
  created() {
    window.addEventListener('resize', this.setFullscreen);
  },
  destroyed() {
    window.removeEventListener('resize', this.setFullscreen);
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    setFullscreen() {
      const h = window.innerHeight;
      const elDialog = this.$refs.home_dialog;
      if (elDialog && elDialog.getElementsByClassName('el-dialog')[0]) {
        const dialogH = elDialog.getElementsByClassName('el-dialog')[0].offsetHeight;
        this.fullscreen = dialogH > h ? true : false;
      }
    },
    open() {
      this.$emit('open');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/vDialog.scss';
</style>
