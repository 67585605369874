import { apiTransactionHistory } from '@/resource'

export default {
  methods: {
    queryTransactionHistory(url, pageNo, pageSize) {
      return apiTransactionHistory(url, {
        pageNo: pageNo,
        pageSize: pageSize,
      })
    },
  },
}
