<template>
  <SelectForm
    v-model="accountNumber"
    :label="$t('common.field.accNum')"
    :no-data-text="$t('common.formValidation.noEligibleAccounts')"
    name="accountNumber"
  >
    <el-option
      v-for="item in accountNumberOptions"
      :key="item.mt4_account"
      :value="item.mt4_account"
      :label="item.mt4_account + ' - ' + item.currency"
      :data-testid="item.mt4_account"
    ></el-option>
  </SelectForm>
</template>

<script>
import { apiQuery_mt_accounts } from '@/resource';

export default {
  name: 'AccountNumber',
  props: {
    supportedCurrencies: String,
    supportedCurrenciesList: Array,
    mt4AccountOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    accountNumber(number) {
      const foundAccount = this.accountNumberOptions.find(f => f.mt4_account === number);
      this.$emit('setAccountNumber', number);
      this.$emit('setCurrency', foundAccount.currency);
    }
  },
  mounted() {
    this.fetchTradingAccount();
  },
  data() {
    return {
      accountNumberOptions: [],
      accountNumber: ''
    };
  },
  methods: {
    fetchTradingAccount() {
      apiQuery_mt_accounts({
        supportedCurrencies: 'all',
        mt4AccountOnly: this.mt4AccountOnly
      }).then(resp => {
        if (resp.data.code == 0) {
          if (this.supportedCurrencies != 'all' && this.supportedCurrencies) {
            this.accountNumberOptions = resp.data.data.filter(f => f.currency == this.supportedCurrencies);
          } else if (this.supportedCurrenciesList) {
            this.accountNumberOptions = resp.data.data.filter(f => this.supportedCurrenciesList.includes(f.currency));
          } else this.accountNumberOptions = resp.data.data;
          if (this.accountNumberOptions.length > 0) this.accountNumber = this.accountNumberOptions[0].mt4_account;
        }
      });
    }
  }
};
</script>
