<template>
  <div>
    <div class="table_item_box">
      <div class="table_box">
        <el-table :data="depositTable" style="width: 100%">
          <el-table-column prop="date" :label="$t('common.column.date')" min-width="100"></el-table-column>
          <el-table-column :label="$t('common.column.tradingAcc')" min-width="120">
            <template slot-scope="accountScope">
              {{ accountScope.row.account + ' (' + accountScope.row.currency + ')' }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.method')" min-width="120">
            <template slot-scope="methodScope">
              <span v-if="methodScope.row.method === 2">
                {{ showMethod2DepositMethod(methodScope.row.channel) }}
              </span>
              <span v-else-if="methodScope.row.method === 15">
                {{ showMethod15DepositMethod(methodScope.row.channel) }}
              </span>
              <span v-else-if="methodScope.row.method === 21">
                {{ showMethod21DepositMethod(methodScope.row.channel) }}
              </span>
              <span v-else>{{ showDepositMethod(methodScope.row.method) }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.amt')" min-width="120">
            <template slot-scope="amountScope">
              {{ amountScope.row.currency | currencyToSymbol }}{{ amountScope.row.amount | formatNumber(2) }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.column.status')" min-width="100">
            <template slot-scope="statusScope">
              {{ depositStatus[statusScope.row.status] | transactionStatus }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 -->
    <div class="page_box">
      <el-pagination
        :background="true"
        :page-size="25"
        layout="prev, pager, next"
        :total="depositTotal"
        :page-sizes="[25, 50, 100]"
        @current-change="handleDepositCurrentChange"
        @size-change="handleDepositSizeChange"
        :current-page.sync="depositPageNo"
        data-testid="pagination"
      ></el-pagination>
    </div>
    <!-- status -->
    <div class="status_box">
      <p class="title">{{ $t('common.field.statusDef') }}</p>
      <ul>
        <li>
          <strong>{{ $t('common.field.incomplete') }}</strong>
          <p>{{ $t('transcationHistory.desc.incomplete') }}</p>
        </li>
        <li>
          <strong>{{ $t('common.field.successful') }}</strong>
          <p>{{ $t('transcationHistory.desc.success') }}</p>
        </li>
        <li>
          <strong>{{ $t('common.field.processing') }}</strong>
          <i18n path="transcationHistory.desc.proc" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_DEPOSIT_EMAIL">{{ GLOBAL_DEPOSIT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.failed') }}</strong>
          <i18n path="transcationHistory.desc.failed" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_DEPOSIT_EMAIL">{{ GLOBAL_DEPOSIT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
        <li>
          <strong>{{ $t('common.field.rejected') }}</strong>
          <i18n path="transcationHistory.desc.rejected" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_DEPOSIT_EMAIL">{{ GLOBAL_DEPOSIT_EMAIL }}</a>
            </template>
          </i18n>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/transactions/transactionHistory';

export default {
  mixins: [mixin],
  data() {
    return {
      depositPageNo: 1,
      depositPageSize: 25,
      depositUrl: 'deposit',
      depositTotal: 0,
      depositTable: [],
      depositMethods: {
        1: 'Credit/Debit Card', // 信用卡
        2: {
          1: 'Bank Wire Transfer (International)', // 银行汇款（国际）
          2: 'Bank Wire Transfer (Australia)', // 银行汇款（澳大利亚）
          3: 'Skrill / Moneybookers', // skrill
          4: 'Neteller', // neteller
          5: 'Bpay', // Bpay
          6: 'POLI Payment', // Poli Payment
          7: 'Broker to Broker Transfer', // Broker To Broker Transfer
          8: 'Wechat Pay', // Wechat Pay  微信支付
          175: 'Bank Transfer (International)', // 银行汇款（Equals）
          188: 'Bank Transfer (International)' // 银行汇款（Equals）
        }, // 线下支付 "Offline Payment"
        15: {
          1: 'cryptobitcoin',
          2: 'cryptotetheromni',
          3: 'cryptotethererc20',
          4: 'cryptotethererc20',
          5: 'cryptotetheromni',
          6: 'cryptobitcoin',
          7: 'cryptotethertrc20'
        },
        74: 'Wise P2P',
        69: 'Banxa CC'
      },
      depositStatus: {
        1: 'Incomplete',
        2: 'Incomplete',
        3: 'Failed',
        4: 'Processing',
        5: 'Successful',
        6: 'Processing',
        7: 'Rejected',
        8: 'Processing',
        9: 'Processing',
        11: 'Successful'
      }
    };
  },
  methods: {
    handleDepositCurrentChange(pageNo) {
      this.depositPageNo = pageNo;
      this.initTransactionHistory();
    },
    handleDepositSizeChange(size) {
      this.depositPageSize = size;
      this.initTransactionHistory();
    },
    showMethod15DepositMethod(channel) {
      return this.depositMethods[15][channel]
        ? this.$options.filters.channelMethods(this.depositMethods[15][channel])
        : channel;
    },
    showMethod21DepositMethod(channel) {
      return this.depositMethods[21][channel]
        ? this.$options.filters.channelMethods(this.depositMethods[21][channel])
        : channel;
    },
    showMethod2DepositMethod(channel) {
      return this.depositMethods[2][channel]
        ? this.$options.filters.channelMethods(this.depositMethods[2][channel])
        : channel;
    },
    showDepositMethod(method) {
      return this.depositMethods[method] ? this.$options.filters.channelMethods(this.depositMethods[method]) : method;
    },
    initTransactionHistory() {
      this.queryTransactionHistory(this.depositUrl, this.depositPageNo, this.depositPageSize).then(resp => {
        if (resp.data.code === 0) {
          this.depositTotal = resp.data.data.total;
          this.depositTable = resp.data.data.depositHistory;
        }
      });
    }
  },
  mounted() {
    this.initTransactionHistory();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
