<template>
  <div id="transferHistory">
    <div class="table_box">
      <el-table :data="displayData">
        <el-table-column prop="date" :label="$t('common.column.date')" min-width="150">
          <template slot-scope="scope">{{ scope.row.date | dateFormat }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.key.from')" min-width="120">
          <template slot-scope="scope">
            {{ scope.row.fromAccount + ' (' + scope.row.from_currency + ')' }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('common.key.to')" min-width="120">
          <template slot-scope="scope">{{ scope.row.toAccount + ' (' + scope.row.to_currency + ')' }}</template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('common.column.amt')" min-width="120">
          <template slot-scope="scope">{{ scope.row.amount | formatNumber }}</template>
        </el-table-column>
        <el-table-column :label="$t('common.column.status')" min-width="120">
          <template slot-scope="scope">{{ scope.row.status | payStatus }}</template>
        </el-table-column>
        <el-table-column prop="notes" :label="$t('common.column.procNote')" min-width="150"></el-table-column>
      </el-table>
    </div>
    <pagination v-bind:table-data="tableData" v-bind:display-data.sync="displayData"></pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { apiTransferHistoryGet } from '@/resource';

export default {
  components: { Pagination },
  data() {
    return {
      userId: this.$store.state.common.uuid,
      tableData: [],
      displayData: []
    };
  },
  mounted() {
    apiTransferHistoryGet({ userId: this.userId }).then(response => {
      this.tableData = response.data;
    });
  }
};
</script>
