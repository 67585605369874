<template>
  <div id="transactionHistory">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('menu.transactionHis') }}</h2>
          <p></p>
        </div>
        <ul class="tab_demo">
          <li
            :class="{ active: activeName === 'deposit' }"
            @click="activeName = 'deposit'"
            class="white_button"
            data-testid="deposit"
          >
            {{ $t('transcationHistory.deposit') }}
          </li>
          <li
            :class="{ active: activeName === 'withdraw' }"
            @click="activeName = 'withdraw'"
            class="white_button"
            data-testid="withdraw"
          >
            {{ $t('transcationHistory.withdraw') }}
          </li>
          <li
            :class="{ active: activeName === 'transfer' }"
            @click="activeName = 'transfer'"
            class="white_button"
            data-testid="transfer"
          >
            {{ $t('menu.tranHis') }}
          </li>
          <li class="export_tool">
            <DateRangePicker
              :startDate="startDate"
              :endDate="endDate"
              :startDateOption="dateOption"
              :endDateOption="dateOption"
              @change="onPickerChange"
            />
            <el-button class="export_btn purple_button" @click="onExportClick">
              {{ $t('common.button.export') }}
            </el-button>
          </li>
        </ul>
        <ul class="table_demo">
          <li class="table_item" :class="{ active: activeName === 'deposit' }"><DepositHistory /></li>
          <li class="table_item" :class="{ active: activeName === 'withdraw' }">
            <WithdrawalHistory />
          </li>
          <li class="table_item" :class="{ active: activeName === 'transfer' }"><TransferHistory /></li>
        </ul>
      </div>
    </div>
    <v-dialog :visible.sync="dialogVisible">
      <div class="dialog_body">
        <el-form label-position="top" :model="form" ref="form" status-icon>
          <AccountNumber supportedCurrencies="all" @setAccountNumber="setAccountNumber"></AccountNumber>
          <div class="dialog_btn_box">
            <el-button class="purple_button" @click="onConfirm" data-testid="ok">
              {{ $t('common.button.ok') }}
            </el-button>
            <el-button class="white_button" @click="dialogVisible = false" data-testid="cancel">
              {{ $t('common.button.cancel') }}
            </el-button>
          </div>
        </el-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { apiDownloadAccountTransactionHistoryPDF } from '@/resource';
import DepositHistory from '@/components/transactions/DepositHistory';
import TransferHistory from '@/components/transactions/TransferHistory';
import WithdrawalHistory from '@/components/transactions/WithdrawalHistory';
import vDialog from '@/components/home/vDialog';
import AccountNumber from '@/components/form/AccountNumber';
import DateRangePicker from '@/components/DateRangePicker';
import moment from 'moment';

export default {
  name: 'TransactionHistory',
  components: { TransferHistory, DepositHistory, WithdrawalHistory, vDialog, AccountNumber, DateRangePicker },
  data() {
    const defaultRange = 7;
    const exportEndDate = new Date();
    const exportStartDate = new Date(
      exportEndDate.getFullYear(),
      exportEndDate.getMonth(),
      exportEndDate.getDate() - defaultRange
    );
    return {
      startDate: exportStartDate,
      endDate: exportEndDate,
      activeName: 'deposit',
      dateFormat: 'YYYY-MM-DD',
      dateOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      form: {
        accountNumber: null
      },
      dialogVisible: false
    };
  },
  methods: {
    onExportClick() {
      this.dialogVisible = true;
    },
    setAccountNumber(accountNumber) {
      this.form.accountNumber = accountNumber;
    },
    onConfirm() {
      const reqParam = {
        startDate: moment(this.startDate).format(this.dateFormat),
        endDate: moment(this.endDate).format(this.dateFormat),
        accountNumber: this.form.accountNumber.toString()
      };
      apiDownloadAccountTransactionHistoryPDF(reqParam)
        .then(resp => {
          if (resp.data.code == 0) {
            this.pdfHandler(resp.data.data, reqParam);
          } else {
            this.errorMessage(this.$t('responseMsg.500'));
            this.dialogVisible = false;
          }
        })
        .catch(err => {
          this.errorMessage(this.$t('responseMsg.500'));
          this.dialogVisible = false;
        });
    },
    onPickerChange(val) {
      this.startDate = val[0];
      this.endDate = val[1];
    },
    errorMessage(message) {
      this.$message({
        message: message,
        type: 'error'
      });
    },
    pdfHandler(data, reqParam) {
      const { startDate, endDate, accountNumber } = reqParam;
      const filename = `${accountNumber} ${startDate} to ${endDate}.pdf`;
      const bufferArray = this.base64ToArrayBuffer(data);
      const blob = new Blob([bufferArray], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      const linkId = 'export_file';
      link.setAttribute('id', linkId);
      link.setAttribute('href', objectUrl);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.getElementById(linkId).remove();
      this.dialogVisible = false;
    },
    base64ToArrayBuffer(data) {
      const bString = window.atob(data);
      const bLength = bString.length;
      let bytes = new Uint8Array(bLength);
      for (let i = 0; i < bLength; i++) {
        bytes[i] = bString.charCodeAt(i);
      }
      return bytes;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/transactionHistory.scss';
</style>
